import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { useApp } from "../AppContext";
import { Link } from "preact-router/match";
import ProductPreview from "../components/ProductPreview";
import KlarnaCheckout from "../components/KlarnaCheckout";
import { route } from "preact-router";

export default function Checkout({ campaign, path }) {
  const { state } = useApp();
  useEffect(() => {
    plausible("checkout", {
      props: { product: path, campaign },
    });
  }, []);
  if (!state.checkoutData) {
    route(`/product/${path}`);
  }
  return (
    <div class="flex flex-col max-w-screen-sm mx-auto p-2">
      <div class="text-gray-400 text-xs flex justify-between items-center">
        <div></div>
        <div>
          <Link href={`/${campaign}/${path}/cancel`}>Tillbaka</Link>
        </div>
      </div>
      <div>
        <div class="w-40 mx-auto">
          <ProductPreview product={state.checkoutData.product} />
        </div>
      </div>
      <div>
        <KlarnaCheckout checkoutData={state.checkoutData} />
      </div>
    </div>
  );
}
