import { h } from "preact";
import ProductSvg from "./ProductSvg";
import render from "preact-render-to-string";

export default function ProductPreview({ product }) {
  // const w = (40 * 72) / 2.54;
  // console.log(render(<ProductSvg product={product} displayWidth={w} />));
  return (
    <div id="preview">
      <ProductSvg product={product} />
    </div>
  );
}
