import { h, render } from "preact";
import App from "./App.jsx";
import { AppProvider } from "./AppContext.jsx";

render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.body
);
