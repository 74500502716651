import { h } from "preact";
import { useState } from "preact/hooks";
import { useApp } from "../AppContext";
import { Link } from "preact-router/match";
import Layout from "../components/Layout";

export default function Index() {
  const { state } = useApp();
  return (
    <Layout>
      <div class="space-y-4 py-10">
        <h3>Välj produkt</h3>
        <div>
          <Link class="underline" href="/product/min-gata">
            Min gata
          </Link>
        </div>
      </div>
    </Layout>
  );
}
