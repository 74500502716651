import { h } from "preact";
import { useState, useRef, useEffect } from "preact/hooks";
import { Link } from "preact-router/match";
import Cart from "./Cart";

export default function Layout({ children }) {
  return (
    <div class="bg-white max-w-screen-md mx-auto">
      <div>{children}</div>
      <div class="bg-gray-800 text-gray-400 text-sm">
        <div class="flex flex-col lg:flex-row lg:justify-around">
          <div class="space-y-4 flex-1 px-10 py-10">
            <div class="font-bold text-white">Bra info</div>
            <ul class="space-y-1">
              <li>Fri frakt i hela Sverige. </li>
              <li> Moms ingår i priset.</li>
              <li>Postern levereras i papprör utan ram.</li>
              <li>Leveranstid 1-2 veckor.</li>
              <li>
                Din specialbeställda order går direkt till tryck och kan ej
                ångras.
              </li>
            </ul>
          </div>
          <div class="space-y-4 flex-1 px-10 py-10">
            <div class="font-bold text-white">Om KNAADA</div>
            <ul class="space-y-1">
              <li>Svensk design &amp; produktion. </li>
              <li>Grundare och formgivare Sofia Leanders.</li>
              <li>
                KNAADAs lokaler är belägna i en gammal skola mitt i sköna
                Hälsingland.
              </li>
              <li>Skolbacken 9, 828 94 Edsbyn. Kontakt info@knaada.se</li>
            </ul>
          </div>
        </div>
        <hr class="mb-6 opacity-25" />
        <div class="space-y-4 px-10 pb-10 w-full lg:w-1/2">
          <div class="font-bold text-white">Villkor</div>
          <div class="space-y-6">
            <ul>
              <li class="font-bold">Ångerrätt &amp; Reklamation</li>
              <li>
                Ångerrätten gäller inte den produkt som har tillverkats särskilt
                åt dig. <br />
                Om varan på något sätt är skadad eller inte motsvarar det som
                avtalats, kontakta Knaada AB snarast via info@knaada.se
              </li>
            </ul>
            <ul>
              <li class="font-bold">Integritetspolicy</li>
              <li>
                Läs mer om KNAADAs integritetspolicy här:{" "}
                <a
                  class="underline"
                  href="https://knaada.se/policies/privacy-policy"
                >
                  https://knaada.se/policies/privacy-policy
                </a>
              </li>
            </ul>
            <ul>
              <li class="font-bold">Minderåriga</li>
              <li>
                Personer under 18 år måste ha målsmans godkännande för att
                handla av Knaada AB.
              </li>
            </ul>
            <ul>
              <li class="font-bold">Outhämtade paket</li>
              <li>
                Ett outhämtat paket betraktas inte som ett utnyttjande av
                ångerrätten och du som kund kommer att faktureras 200 kr för
                våra omkostnader kring returen.
              </li>
            </ul>
            <ul>
              <li class="font-bold">Reservationer</li>
              <li>
                Knaada AB reserverar sig för vissa färgskillnader mellan din
                skärm och det egentliga trycket. Vi gör vårt bästa för att
                återge färgerna korrekt på webben, men alla skärmar och
                skärminställningar är olika och hur den ser ut hos dig kan vi
                inte svara för.
              </li>
            </ul>
            <ul>
              <li class="font-bold">Rättigheter</li>
              <li>
                Allt material på denna webbplats är skyddat enligt lagen om
                upphovsrätt. Lagen om upphovrätt är också gällande varje enskilt
                plakat.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
