import { h } from "preact";
import Product from "../components/Product";
import Modal from "react-modal";
import { useState, useRef, useEffect } from "preact/hooks";
import Layout from "../components/Layout";
import { useApp } from "../AppContext";

function Receipt({ closeModal, orderId }) {
  const { postApi } = useApp();
  useEffect(() => {
    const load = async () => {
      const result = await postApi({
        path: "/klarna-confirmation",
        data: { orderId },
      });
      var confirmationContainer = document.getElementById(
        "my-confirmation-container"
      );
      confirmationContainer.innerHTML = result.data.html_snippet;
      var scriptsTags = confirmationContainer.getElementsByTagName("script");
      // This is necessary otherwise the scripts tags are not going to be evaluated
      for (var i = 0; i < scriptsTags.length; i++) {
        var parentNode = scriptsTags[i].parentNode;
        var newScriptTag = document.createElement("script");
        newScriptTag.type = "text/javascript";
        newScriptTag.text = scriptsTags[i].text;
        parentNode.removeChild(scriptsTags[i]);
        parentNode.appendChild(newScriptTag);
      }
    };
    load();
  }, []);
  return (
    <div class="fiex flex-col space-y-6">
      <div class="flex justify-end items-center">
        <div>
          <button onClick={closeModal}>
            <svg
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
            >
              <path
                d="M4.5 4.5l6 6m-6 0l6-6m-3 10a7 7 0 110-14 7 7 0 010 14z"
                stroke="currentColor"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div id="my-confirmation-container"></div>
    </div>
  );
}

export default function ProductPage({
  path,
  campaign,
  receipt,
  cancel,
  order_id,
}) {
  const [isModalOpen, setIsModalOpen] = useState(true);

  function closeModal() {
    setIsModalOpen(false);
  }

  useEffect(() => {
    if (receipt) {
      plausible("receipt", {
        props: { product: path, campaign },
      });
    } else if (cancel) {
      plausible("cancel", {
        props: { product: path, campaign },
      });
    } else {
      plausible("visit", {
        props: { product: path, campaign },
      });
    }
  }, []);

  return (
    <Layout>
      <div class="flex flex-1">
        <Product path={path} campaign={campaign} />
        {receipt && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Kvitto"
          >
            <Receipt closeModal={closeModal} orderId={order_id} />
          </Modal>
        )}
      </div>
    </Layout>
  );
}
