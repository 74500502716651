import { h, Fragment } from "preact";
import Router from "preact-router";
import { useApp } from "./AppContext";
import IndexPage from "./pages/Index";
import ProductPage from "./pages/Product";
import CheckoutPage from "./pages/Checkout";

export default function App() {
  const { isLoading } = useApp();
  return (
    <Fragment>
      <Router>
        <IndexPage path="/" />
        <ProductPage path="/:campaign/:path" />
        <ProductPage path="/:campaign/:path/receipt" receipt={true} />
        <ProductPage path="/:campaign/:path/cancel" cancel={true} />
        <CheckoutPage path="/:campaign/:path/checkout" />
      </Router>
      {isLoading && (
        <div
          class="fixed flex justify-center items-center bg-white z-300 inset-0"
          style="background-color: rgba(255,255,255,0.9);"
        >
          <div>
            <div class="loader">Loading...</div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
