import { h, createContext } from "preact";
import { useContext, useState } from "preact/hooks";
import { useImmerReducer } from "use-immer";
import { route } from "preact-router";

const initialState = {
  user: null,
  isLoading: false,
  cart: [],
};

const reducer = (draft, action) => {
  const { type, payload } = action;
  switch (type) {
    case "setUser": {
      draft.user = payload;
      return;
    }
    case "setIsLoading": {
      draft.isLoading = payload;
      return;
    }
    case "setCheckoutData": {
      const { cancelUrl, successUrl, product, image, campaign, path } = payload;
      const description = `${product.format.name} - ${
        product.variant.name
      } - ${product.data
        .map((item) => item.label + ": " + item.value)
        .join(", ")}`;
      draft.checkoutData = {
        cancelUrl,
        successUrl,
        product,
        image,
        campaign,
        path,
        description,
      };
      return;
    }
  }
};

const AppContext = createContext(initialState);

export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);

  function setCheckoutData({
    cancelUrl,
    successUrl,
    product,
    image,
    campaign,
    path,
  }) {
    dispatch({
      type: "setCheckoutData",
      payload: { cancelUrl, successUrl, product, image, campaign, path },
    });
  }

  async function postApi({ path, data = {}, spinner = true }) {
    if (spinner) {
      setIsLoading(true);
    }
    const result = await fetch(`/api${path}`, {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      method: "POST",
    }).then((resp) => resp.json());
    if (spinner) {
      setIsLoading(false);
    }
    return result;
  }

  async function checkout({
    cancelUrl,
    successUrl,
    product,
    image,
    campaign,
    path,
  }) {
    setCheckoutData({ cancelUrl, successUrl, product, image, campaign, path });
    route(`/${campaign}/${path}/checkout`);
  }

  // async function checkoutStripe({ cancelUrl, successUrl, product, image, campaign, path }) {
  //   const session = await postApi({
  //     path: "/create-checkout-session",
  //     data: {
  //       successUrl: `${successUrl}?session_id={CHECKOUT_SESSION_ID}`,
  //       cancelUrl: cancelUrl,
  //       product,
  //       image,
  //     },
  //   });
  //   var stripe = Stripe(process.env.STRIPE_PUBLIC_KEY);
  //   await stripe.redirectToCheckout({ sessionId: session.data.id });
  // }

  async function klarnaCheckout({ product, description, image }) {
    const session = await postApi({
      path: "/create-klarna-checkout-session",
      data: { product, description, image },
    });
    return session;
  }

  return (
    <AppContext.Provider
      value={{
        isLoading,
        state,
        dispatch,
        postApi,
        checkout,
        klarnaCheckout,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
