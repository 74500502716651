import { h } from "preact";
import { useEffect, useState, useRef, useCallback } from "preact/hooks";

function getData(product, key) {
  return product.data.find((item) => item.key === key);
}

function getDataValue(data) {
  return data.value ? data.value : data.dirty ? "" : data.default;
}

function FitText({ children, x, y, textAnchor, maxWidth, maxHeight, ...rest }) {
  const textRef = useRef(null);
  const [update, setUpdate] = useState(null);

  useEffect(() => {
    if (textRef.current) {
      const measuredWidth = textRef.current.getComputedTextLength();
      const scaleTo = Math.min(1, maxWidth / measuredWidth);
      textRef.current.setAttribute("transform", `scale(${scaleTo})`);
    }
  }, [children, x, y, update]);

  // bug: ui is stuck for some reason on inittal render. re-render after 500ms
  useEffect(() => {
    let t = setTimeout(() => {
      setUpdate(Date.now());
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        ref={textRef}
        fontSize={maxHeight}
        textAnchor={textAnchor}
        {...rest}
      >
        {children}
      </text>
    </g>
  );
}

export default function ProductSvg({ product, displayWidth }) {
  const scale = useCallback(
    (value) => {
      const factor = displayWidth ? displayWidth / product.width : 1;
      return value * factor;
    },
    [product.width, displayWidth]
  );

  return (
    <svg
      style="background-color:white"
      viewBox={`0 0 ${scale(product.width)} ${scale(product.height)}`}
      id="svg"
      xmlns="http://www.w3.org/2000/svg"
    >
      <image
        x={0}
        y={0}
        href={product.variant.image}
        height="100%"
        width="100%"
      />
      <FitText
        x={scale(780)}
        y={scale(340)}
        maxWidth={scale(550)}
        maxHeight={scale(60)}
        textAnchor="end"
        fill="#000000"
        fontFamily="TrafficTypeW01-SwedenD"
        letterSpacing={scale(2)}
        fillOpacity="0.9"
        dominant-baseline="middle"
      >
        {getDataValue(getData(product, "street"))}
      </FitText>
      <FitText
        x={scale(230)}
        y={scale(480)}
        maxWidth={scale(380)}
        maxHeight={scale(40)}
        textAnchor="start"
        fill="#000000"
        fontFamily="TrafficTypeW01-SwedenD"
        letterSpacing={scale(2)}
        fillOpacity="0.9"
        dominant-baseline="auto"
      >
        {getDataValue(getData(product, "name"))}
      </FitText>
      <FitText
        x={scale(780)}
        y={scale(480)}
        maxWidth={scale(160)}
        maxHeight={scale(80)}
        textAnchor="end"
        fill="#000000"
        fontFamily="TrafficTypeW01-SwedenD"
        letterSpacing={scale(2)}
        fillOpacity="0.9"
        dominant-baseline="auto"
      >
        {getDataValue(getData(product, "streetno"))}
      </FitText>
    </svg>
  );
}
