import { h } from "preact";
import { useApp } from "../AppContext";
import { useEffect } from "preact/hooks";

export default function KlarnaCheckout() {
  const { klarnaCheckout, state } = useApp();
  useEffect(() => {
    // https://developers.klarna.com/documentation/klarna-checkout/get-started/render-checkout-snippet/
    const load = async () => {
      const session = await klarnaCheckout({
        product: state.checkoutData.product,
        description: state.checkoutData.description,
        image: state.checkoutData.image,
      });
      var checkoutContainer = document.getElementById(
        "klarna-checkout-container"
      );
      checkoutContainer.innerHTML = session.data.html_snippet;
      var scriptsTags = checkoutContainer.getElementsByTagName("script");
      // This is necessary otherwise the scripts tags are not going to be evaluated
      for (var i = 0; i < scriptsTags.length; i++) {
        var parentNode = scriptsTags[i].parentNode;
        var newScriptTag = document.createElement("script");
        newScriptTag.type = "text/javascript";
        newScriptTag.text = scriptsTags[i].text;
        parentNode.removeChild(scriptsTags[i]);
        parentNode.appendChild(newScriptTag);
      }
    };
    load();
  }, []);
  return (
    <div id="preview">
      <div id="klarna-checkout-container"></div>
    </div>
  );
}
