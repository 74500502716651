import { h, Fragment } from "preact";
import { useState, useRef, useEffect } from "preact/hooks";
import { Link } from "preact-router/match";
import { useApp } from "../AppContext";
import Modal from "react-modal";
import ProductPreview from "./ProductPreview";

Modal.setAppElement(document.body);

export default function Cart({ children }) {
  const {
    state: { cart },
  } = useApp();

  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  console.log(cart);

  return (
    <Fragment>
      <button
        onClick={openModal}
        class="bg-white flex items-center space-x-2 p-2 px-4 rounded hover:bg-gray-100"
      >
        <div>
          <svg
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
          >
            <path
              d="M.5.5l.6 2m0 0l2.4 8h11v-6a2 2 0 00-2-2H1.1zm11.4 12a1 1 0 110-2 1 1 0 010 2zm-8-1a1 1 0 112 0 1 1 0 01-2 0z"
              stroke="currentColor"
            ></path>
          </svg>
        </div>
        <div>({cart.length})</div>
      </button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div class="fiex flex-col space-y-6">
          <div class="flex justify-between items-center">
            <div class="text-2xl font-bold">Shopping cart</div>
            <div>
              <button onClick={closeModal}>X</button>
            </div>
          </div>
          {cart.map((item) => (
            <div>
              <ProductPreview product={item} width={200} />
            </div>
          ))}
        </div>
      </Modal>
    </Fragment>
  );
}
