export default function cropCanvas({ canvas, dimensions }) {
  const dest = document.createElement("canvas");
  dest.width = dimensions.width;
  dest.height = dimensions.height;
  const ctx = dest.getContext("2d");
  // https://stackoverflow.com/questions/26015497/how-to-resize-then-crop-an-image-with-canvas
  ctx.drawImage(
    canvas,
    dimensions.left,
    dimensions.top,
    dimensions.width,
    dimensions.height,
    0,
    0,
    dimensions.width,
    dimensions.height
  );
  return dest;
}
