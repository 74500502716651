import { h, Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";
import { useApp } from "../AppContext";
import { useImmer } from "use-immer";
import ProductPreview from "./ProductPreview";
import svg2png from "../lib/svg2png";

const products = [
  {
    width: 800,
    height: 600,
    key: "min-gata",
    displayName: "Min Gata Personlig Poster",
    formats: [
      { price: 300, name: "Liten (40x30 cm)", key: "40x30" },
      { price: 460, name: "Stor (70x50 cm)", key: "70x50" },
    ],
    format: { price: 300, name: "Liten (40x30 cm)", key: "40x30" },
    variants: [
      { name: "variant-0", image: "/din-gata-0.jpg" },
      { name: "variant-1", image: "/din-gata-1.jpg" },
      { name: "variant-2", image: "/din-gata-2.jpg" },
      { name: "variant-3", image: "/din-gata-3.jpg" },
      { name: "variant-4", image: "/din-gata-4.jpg" },
      { name: "variant-5", image: "/din-gata-5.jpg" },
      { name: "variant-6", image: "/din-gata-6.jpg" },
    ],
    variant: { name: "variant-0", image: "/din-gata-0.jpg" },
    data: [
      {
        label: "Gata",
        key: "street",
        value: "",
        default: "Min Gata",
        dirty: false,
      },
      {
        label: "Gatunr",
        key: "streetno",
        value: "",
        default: 123,
        dirty: false,
      },
      {
        label: "Namn",
        key: "name",
        value: "",
        default: "Namn",
        dirty: false,
      },
    ],
  },
];

function Form({ product, onChange, setFormat }) {
  return (
    <div class="space-y-2">
      {product.data.map((item, i) => (
        <div class="border flex bg-white items-center rounded shadow">
          <input
            placeholder={item.label}
            class="py-2 px-4 text-lg text-gray-900 flex-1"
            type="text"
            value={item.value}
            onInput={(e) => onChange(i, e.target.value)}
          />
          <div
            class={`text-xs text-right px-4 text-gray-400 opacity-0 uppercase transform transition-all duration-500 ease-out ${
              item.value ? "opacity-100" : "opacity-0"
            }`}
          >
            {item.label}
          </div>
        </div>
      ))}
    </div>
  );
}

export default function Product({ path, campaign }) {
  const { checkout, postApi } = useApp();
  const [isEdited, setIsEdited] = useState(false);
  const [product, setProduct] = useImmer(
    localStorage.getItem("product-5")
      ? JSON.parse(localStorage.getItem("product-5"))
      : products.filter((p) => p.key === path)[0]
  );

  useEffect(() => {
    if (isEdited) {
      localStorage.setItem("product-4", JSON.stringify(product));
    }
  }, [product, isEdited]);

  function modifyProduct(i, value) {
    setProduct((draft) => {
      draft["data"][i]["value"] = value.trim().substring(0, 100);
      draft["data"][i]["dirty"] = true;
    });
    if (!isEdited) {
      plausible("edit", { props: { product: product.key, campaign } });
    }
    setIsEdited(true);
  }

  function setFormat(format) {
    setProduct((draft) => {
      draft["format"] = format;
    });
  }

  return (
    <div class="flex flex-col p-4 space-y-4 bg-gray-300">
      <div>Designa din poster</div>
      <div>
        <Form
          product={product}
          onChange={modifyProduct}
          setFormat={setFormat}
        />
      </div>
      <div
        style="border:16px solid #222222"
        class="p-6 md:p-10 lg:p-12 bg-white lg:my-6 shadow-lg"
      >
        <div class="border-t border-gray-400 border-r border-gray-400">
          <ProductPreview product={product} />
        </div>
      </div>
      <div class="flex flex-col lg:flex-1 px-4 lg:px-8">
        <div class="flex justify-between lg:pb-6">
          {product.variants.map((item) => {
            return (
              <button
                class="block m-1"
                style="max-width:60px"
                onClick={(e) => {
                  setProduct((draft) => {
                    setIsEdited(true);
                    draft["variant"] = item;
                  });
                }}
              >
                <img
                  class={`border ${
                    product.variant.image === item.image
                      ? "border-black"
                      : "border-transparent"
                  }`}
                  src={item.image}
                />
              </button>
            );
          })}
        </div>
      </div>
      <div class="flex space-x-4 justify-center">
        {product.formats.map((item) => (
          <div class="flex space-x-2 text-sm text-gray-600 items-center">
            <input
              onChange={(e) => setFormat(item)}
              id={item.key}
              name="format"
              type="radio"
              checked={product.format.key === item.key}
            />
            <label for={item.key}>{item.name}</label>
          </div>
        ))}
      </div>
      <div class="text-center">
        Pris: {product.format.price * 1.25} kr (Fri frakt)
      </div>
      <button
        onClick={async (e) => {
          plausible("preview", {
            props: { product: product.key, campaign },
          });
          const cancelUrl = `${window.location.origin}/${campaign}/${product.key}/cancel`;
          const successUrl = `${window.location.origin}/${campaign}/${product.key}/receipt`;
          const svgElement = document.getElementById("svg");
          const svgClone = svgElement.cloneNode(true);
          const svgString = svgClone.outerHTML;
          const png = await svg2png({
            svgElement,
            width: 800,
            height: 600,
            download: false,
          });
          await checkout({
            cancelUrl,
            successUrl,
            product,
            image: png,
            campaign,
            path,
          });
        }}
        class={`bg-green-600 rounded text-white text-2xl px-6 py-4 flex justify-center items-baseline space-x-2`}
      >
        <div>Köp nu</div>
      </button>
      <img
        class="h-6"
        src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
      />
      <div class="text-sm text-gray-600 space-y-2">
        <div class="text-center">Svensk Design &amp; Produktion 🇸🇪</div>
        <div class="text-center">
          Skickas från Hälsingland till dig inom 1-2 veckor.
        </div>
        <div class="text-center">
          Postern levereras utan ram till en DHL Servicepoint nära dig.{" "}
        </div>
      </div>
    </div>
  );
}
